import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Images
import facebook from "../../assets/logos/facebook.png";
import youtubetv from "../../assets/logos/youtubetv.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "51.51vw",
    margin: theme.spacing(18, 0, 23)
  },
  youtubetv: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "9.63vw"
    }
  },
  facebook: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "15.78vw"
    }
  },
  amazon: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "9.32vw"
    }
  }
}));

const Sponsors = ({ classes }) => {
  const innerClasses = useStyles();
  return (
    <div className={clsx(innerClasses.root, classes.root)}>
      <img
        className={innerClasses.youtubetv}
        src={youtubetv}
        alt="YouTube TV"
      />
      <img className={innerClasses.facebook} src={facebook} alt="Facebook" />
    </div>
  );
};

Sponsors.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string
  })
};

Sponsors.defaultProps = {
  classes: {
    root: ""
  }
};

export default Sponsors;
