import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Images
import appstore from "../../../../assets/icons/appstore.svg";
import iphonex from "../../../../assets/images/iphonex.png";

// Config
import { externalLinks } from "../../../../routes/routesConfig";
import colors from "../../../../styles/colors";

// Components
import Link from "../../../../components/Link";
import Text from "../../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(20),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.whiteLilac,
    order: 4,

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10)
    },

    [theme.breakpoints.down("sm")]: {
      order: 3,
      padding: "0 8.94vw",
      backgroundColor: "white"
    }
  },
  content: {
    maxWidth: 1311,
    width: "80vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    padding: theme.spacing(10, 0, 15),

    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial",
      width: "100%",
      alignItems: "center",
      padding: theme.spacing(7, 0, 5)
    }
  },
  heading: {
    zIndex: 10,
    marginBottom: theme.spacing(2),
    textTransform: "capitalize",
    color: colors.violetRed,

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7)
    }
  },
  title: {
    zIndex: 10,
    marginBottom: theme.spacing(2),
    maxWidth: 612,
    width: "44vw",
    textTransform: "capitalize",

    [theme.breakpoints.up("md")]: {
      fontSize: 32,
      width: "50%"
    },

    [theme.breakpoints.down("sm")]: {
      width: "auto"
    }
  },
  description: {
    zIndex: 10,
    maxWidth: 524,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up("md")]: {
      width: "50%"
    }
  },
  iphonex: {
    position: "absolute",
    right: 0,
    top: 0,
    maxWidth: 547,
    width: "28.48vw",
    zIndex: 1,

    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "73.67vw",
      maxWidth: 305,
      marginBottom: 4
    }
  },
  appstore: {
    [theme.breakpoints.down("md")]: {
      width: 160
    },
    [theme.breakpoints.down("sm")]: {
      width: 125
    }
  }
}));

const OurProducts = () => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <Text className={classes.heading} type="small">
          Our Products
        </Text>

        <img src={iphonex} alt="Our Products" className={classes.iphonex} />

        <Text className={classes.title} type="title">
          Football, Esports, Boxing, Basketball, Wrestling, Golf, Cricket, and
          more.
        </Text>
        <Text className={classes.description} type="description">
          Experience Buff for yourself on our partner products.
        </Text>

        <Link to={externalLinks.appstore} target="blank">
          <img src={appstore} alt="AppStore" className={classes.appstore} />
        </Link>
      </div>
    </section>
  );
};

export default OurProducts;
