import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Assets
import colors from "../../../../styles/colors";
import livequest from "../../../../assets/images/img_livequest.png";
import livequestMobile from "../../../../assets/images/img_livequest_responsive.png";

// Components
import AndMuchMore from "./AndMuchMore";
import Features from "./Features";
import Text from "../../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.whiteLilac,
    paddingTop: theme.spacing(17),
    order: 1,

    [theme.breakpoints.down("md")]: {
      paddingTop: 0
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4)
    }
  },
  heading: {
    position: "relative",
    margin: "0 auto",
    maxWidth: "47.92vw",
    padding: theme.spacing(17, 0, 0),
    paddingBottom: "19.16vw",

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(11)
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: 266,
      marginBottom: theme.spacing(4),
      padding: 0
    }
  },
  small: {
    color: colors.violetRed,
    textTransform: "capitalize"
  },
  livequest: {
    position: "absolute",
    left: "50%",
    bottom: "-20vw",
    transform: "translateX(-50%)",
    width: "76.82vw"
  },
  livequestMobile: {
    width: "100vw",
    paddingRight: theme.spacing(2.5)
  }
}));

const FeaturesSection = () => {
  const classes = useStyles();
  const title = "Features & Benefits";
  return (
    <section className={classes.root} id="features">
      <div className={classes.heading}>
        <Text align="center" type="small" className={classes.small}>
          How <b>BUFF</b> Works
        </Text>
        <Text align="center" type="title" className={classes.title}>
          {title}
        </Text>
        <Text align="center" type="subtitle" className={classes.subtitle}>
          How our Platform will enrich your content and engage your subscribers
        </Text>
        <Hidden smDown>
          <img className={classes.livequest} src={livequest} alt="Live Quest" />
        </Hidden>
      </div>
      <Hidden mdUp>
        <img
          className={classes.livequestMobile}
          src={livequestMobile}
          alt="Live Quest"
        />
      </Hidden>
      <Features />
      <AndMuchMore />
    </section>
  );
};

export default FeaturesSection;
