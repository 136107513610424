import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Images
import avatars from "../../../../assets/images/avatars.png";
import friending from "../../../../assets/icons/friending.svg";
import points from "../../../../assets/icons/points.svg";
import process from "../../../../assets/icons/process.svg";
import responsive from "../../../../assets/icons/responsive.svg";
import socialmedia from "../../../../assets/icons/socialmedia.svg";
import starr from "../../../../assets/icons/starr.svg";
import tick from "../../../../assets/icons/tick.svg";
import youtube from "../../../../assets/icons/youtube.svg";

// Components
import Card from "../../../../components/Card";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    padding: "0 9.58vw",
    justifyContent: "space-between",
    alignItems: "stretch",
    margin: theme.spacing(13, 0),
    width: "100%",

    [theme.breakpoints.down("md")]: {
      padding: "0 5vw",
      margin: theme.spacing(7, 0, 13)
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 0,
      margin: theme.spacing(2, 0, 6)
    }
  },
  points: {
    top: 0,
    right: 44,
    transform: "translateY(-50%)",
    position: "absolute",

    [theme.breakpoints.down("md")]: {
      width: 90,
      right: theme.spacing(3)
    },

    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 0,
      transform: "none",
      width: 66
    }
  },
  tick: {
    bottom: 0,
    right: 32,
    transform: "translateY(50%)",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: 70
    }
  },
  avatars: {
    top: 0,
    right: 31,
    transform: "translateY(-25%)",
    position: "absolute",

    [theme.breakpoints.down("lg")]: {
      width: "12.29vw"
    },

    [theme.breakpoints.down("md")]: {
      right: theme.spacing(2)
    },

    [theme.breakpoints.down("sm")]: {
      right: 0,
      width: 112
    }
  },
  friending: {
    bottom: theme.spacing(2),
    left: "50%",
    transform: "translateX(-50%)",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: 65
    }
  },
  youtube: {
    top: 100,
    right: -30,
    position: "absolute",

    [theme.breakpoints.down("md")]: {
      right: -25,
      width: 50
    },

    [theme.breakpoints.down("sm")]: {
      width: 34,
      top: "inherit",
      bottom: 0,
      right: 0
    }
  },
  starr: {
    bottom: 46,
    left: 0,
    transform: "translateX(-50%)",
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      width: 50
    }
  }
}));

const HowBuffWorks = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Card
        body="AI driven engagements powered by live market data, combined with user
          generated and curated content."
        heading="Live Predictions, Polling, Quizzes"
        src={process}
        alt="Process"
      >
        <img src={points} alt="Points" className={classes.points} />
        <Hidden smDown>
          <img src={tick} alt="Tick" className={classes.tick} />
        </Hidden>
      </Card>

      <Card
        body="Audience prove they can top the rankings, beat their friends, and
        collect the rewards"
        heading="Ratings, Rankings, Gamification, Rewards"
        src={socialmedia}
        alt="Social Media"
      >
        <img src={avatars} alt="Users" className={classes.avatars} />
        <Hidden smDown>
          <img src={friending} alt="Friending" className={classes.friending} />
        </Hidden>
      </Card>

      <Card
        body="Viewers stay longer, bring their friends, spend more and give
        permission based data for further engagement."
        heading="Retention, Acquisition, Monetisation, Data"
        src={responsive}
        alt="Responsive"
      >
        <img src={youtube} alt="youtube" className={classes.youtube} />
        <Hidden smDown>
          <img src={starr} alt="Fav" className={classes.starr} />
        </Hidden>
      </Card>
    </section>
  );
};

export default HowBuffWorks;
