import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  BroadcastSection,
  FeaturesSection,
  HowItWorks,
  OurProducts
} from "./sections";
import { rawClasses } from "../../styles/styles";

const useStyles = makeStyles(theme => ({
  root: {
    ...rawClasses.main,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      ...rawClasses.mainMd
    },
    [theme.breakpoints.down("sm")]: {
      ...rawClasses.mainMobile
    }
  }
}));

const ProductPage = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <BroadcastSection />
      <FeaturesSection />
      <HowItWorks />
      <OurProducts />
    </main>
  );
};

export default ProductPage;
