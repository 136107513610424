export default {
  alto: "#CECECE",
  black: "#020202",
  dustyGray: "#9A9A9A",
  frenchGray: "#B7B7C3",
  fullBlack: "#000",
  inputBorderColor: "#7070703C",
  inputShadowColor: "#455B6314",
  lynch: "#78849E",
  mineShaft: "#1F1F1F",
  mischka: "#D1D1D6",
  red: "#FF0000",
  rhino: "#1F1F1F", // original: #26415E
  shadowColor: "#00000029",
  shadowColor2: "#00000014",
  tundora: "#404040",
  turquoise: "#50E3C2",
  violetRed: "#EE2526", // original: #FC3F78
  whiteLilac: "#FAFAFA" // original: #F8F9FC,
};
