import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import colors from "./styles/colors";
import { breakpoints } from "./styles/styles";
import Routes from "./routes/Routes";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Maven Pro", "Arial", "sans-serif"].join(",")
  },
  palette: {
    common: {
      black: colors.black
    },
    primary: {
      main: colors.violetRed
    }
  },
  shadows: Array(25).fill("none"),
  breakpoints
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
}

export default App;
