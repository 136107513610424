import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from "./routesConfig";
import Footer from "../containers/Footer";
import Navbar from "../containers/Navbar";

const Routes = () => {
  return (
    <Router>
      <Navbar />
      <Switch>
        {routes.map(route => (
          <Route
            key={route.id}
            exact={route.isExact}
            path={route.path}
            render={props => <route.component {...props} {...route.props} />}
          />
        ))}
      </Switch>
      <Footer />
    </Router>
  );
};

export default Routes;
