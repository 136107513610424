import React, { useState } from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";

// Components
import ActiveButton from '../../../../components/ActiveButton'
import Text from '../../../../components/Text'

// Icons
import check from "../../../../assets/icons/check.svg";

// Config
import { formspreeAction } from "../../../../routes/routesConfig";
import { values } from "../../../../styles/styles";
import colors from "../../../../styles/colors";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0, 0),
    display: 'flex',
    flexDirection: 'column',
    width: '27.7vw',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 325,
      padding: 0
    }
  },
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(0.7)
    }
  },
  input: {
    padding: theme.spacing(1, 2),
    backgroundColor: 'white',
    border: '0.5px solid #7070703C',
    borderRadius: '12px',
    marginBottom: theme.spacing(2),
    boxShadow: values.inputShadow,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0.5, 1.5)
    }
  },
  multiline: {
    padding: theme.spacing(2),
    backgroundColor: 'white',
    border: '0.5px solid #7070703C',
    borderRadius: '12px',
    boxShadow: values.inputShadow,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      padding: theme.spacing(1, 1.5)
    }
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  button: {
    width: 172,
    fontSize: 21,
    padding: theme.spacing(1.5, 8),

    [theme.breakpoints.down('md')]: {
      width: 115,
      fontSize: 16,
      padding: theme.spacing(1, 5)
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(2, 0)
    }
  },
  buttonSuccess: {
    backgroundColor: `${colors.turquoise} !important`,
    color: "white !important"
  },
  successIcon: {
    margin: theme.spacing(0, 0, 0, 2)
  }
}))

const Form = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [state, setState] = useState({
    name: "",
    company: "",
    job: "",
    email: "",
    message: ""
  });

  const handleChange = ({ target: { name, value } }) => {
    setState({ ...state, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);
    fetch(formspreeAction, {
      method: "POST",
      body: JSON.stringify(state),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
      setLoading(false);
      setSuccess(true);
    });
  };

  return (
    <form className={classes.root} onSubmit={onSubmit}>
      <div className={classes.inputWrapper}>
        <Text type="label" className={classes.label}>
          Full Name
        </Text>
        <InputBase
          className={classes.input}
          disabled={loading}
          name="name"
          onChange={handleChange}
          required
          value={state.name}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text type="label" className={classes.label}>
          Company
        </Text>
        <InputBase
          className={classes.input}
          disabled={loading}
          name="company"
          onChange={handleChange}
          required
          value={state.company}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text type="label" className={classes.label}>
          Job Title
        </Text>
        <InputBase
          className={classes.input}
          disabled={loading}
          name="job"
          onChange={handleChange}
          required
          value={state.job}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text type="label" className={classes.label}>
          Email Address
        </Text>
        <InputBase
          className={classes.input}
          disabled={loading}
          name="email"
          onChange={handleChange}
          required
          type="email"
          value={state.email}
        />
      </div>
      <div className={classes.inputWrapper}>
        <Text type="label" className={classes.label}>
          Drop Us a Line
        </Text>
        <InputBase
          className={classes.multiline}
          disabled={loading}
          multiline
          name="message"
          onChange={handleChange}
          required
          rows="3"
          rowsMax="8"
          value={state.message}
        />
      </div>
      <ActiveButton
        disabled={loading || success}
        type="submit"
        className={clsx(classes.button, {
          [classes.buttonSuccess]: success
        })}
      >
        {loading && <CircularProgress size={21} />}
        {!loading &&
          (success ? (
            <>
              Sent!
              <img src={check} alt="" className={classes.successIcon} />
            </>
          ) : (
            "Send"
          ))}
      </ActiveButton>
    </form>
  )
}

export default Form
