import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hidden from "@material-ui/core/Hidden";

// Components
import Text from "../../../../components/Text";
import CultureCarousel from "./CultureCarousel";

// Images
import office1 from "../../../../assets/images/Office1.png";
import logo from "../../../../assets/logos/BuffLogo.svg";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(10, 0),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(10, 0, 0)
    }
  },
  content: {
    margin: "0 9.27vw 0 6.56vw",
    maxWidth: 749,

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      margin: "0 auto"
    }
  },
  heading: {
    opacity: 0.5,
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    textTransform: "capitalize",

    [theme.breakpoints.down("sm")]: {
      maxWidth: 140,
      margin: "0 auto",
      marginBottom: theme.spacing(1.5)
    }
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      maxWidth: 350,
      marginBottom: theme.spacing(3)
    }
  },
  image: {
    width: "100vw",
    display: "block"
  },
  logo: {
    width: 116,
    marginBottom: theme.spacing(4),
    margin: "0 auto"
  }
}));

const OurProducts = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section className={classes.root}>
      <Hidden mdUp>
        <img className={classes.logo} src={logo} alt="BUFF" />
      </Hidden>
      <div className={classes.content}>
        {!matches && (
          <Text className={classes.heading} type="subtitle">
            We Are <b>BUFF</b>
          </Text>
        )}
        <Text className={classes.title} type="title">
          This is our culture
        </Text>
        <Text className={classes.description} type="description">
          We’re looking for insanely talented folks with a massive passion for
          sports to join us as we revolutionise the live sports experience for
          fans around the globe.
        </Text>
      </div>
      <div>
        {matches ? (
          <img src={office1} alt="Our office" className={classes.image} />
        ) : (
          <CultureCarousel />
        )}
      </div>
    </section>
  );
};

export default OurProducts;
