import colors from "./colors";

export const values = {
  boxShadow: `0px 3px 6px ${colors.shadowColor}`,
  boxShadow2: `-2px 10px 41px ${colors.shadowColor2}`,
  boxShadow3: `-40px 0px 46px ${colors.shadowColor}`,
  boxShadow4: `0px 3px 45px ${colors.shadowColor}`,
  inputBorder: `0.5px solid ${colors.inputBorderColor}`,
  inputShadow: `0px 4px 16px ${colors.shadowColor}`,
  navbarHeight: 240,
  navbarHeightMd: 152
};

export const rawClasses = {
  highlightSection: {
    backgroundColor: colors.violetRed,
    padding: "240px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  highlightSectionMd: {
    padding: "80px 0"
  },
  highlightContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    color: "white",
    padding: "0 24vw",
    width: "100%"
  },
  main: {
    marginTop: values.navbarHeight,
    maxWidth: 1920,
    margin: "0 auto"
  },
  mainMd: {
    marginTop: values.navbarHeightMd
  },
  mainMobile: {
    marginTop: 41
  }
};

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1367,
    xl: 1920
  }
};
