import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Config
import { externalLinks } from "../../../../routes/routesConfig";
import { values, rawClasses } from "../../../../styles/styles";
import colors from "../../../../styles/colors";

// Components
import ActiveButton from "../../../../components/ActiveButton";
import Text from "../../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    ...rawClasses.highlightSection,
    padding: "136px 0",

    [theme.breakpoints.down("md")]: {
      ...rawClasses.highlightSectionMd
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(8.5, 0)
    }
  },
  content: rawClasses.highlightContent,
  heading: {
    marginBottom: theme.spacing(5),
    textTransform: "capitalize",

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2.75)
    }
  },
  text: {
    color: "inherit",
    textTransform: "inherit",
    marginBottom: theme.spacing(5),
    fontWeight: theme.typography.fontWeightRegular,

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2.75),
      maxWidth: 327,
      width: "79vw",
      fontSize: 26
    }
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(2.5),
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "78%",
      padding: theme.spacing(2.5, 0)
    }
  },
  button: {
    backgroundColor: "white",
    color: colors.violetRed,
    boxShadow: values.boxShadow,
    margin: theme.spacing(0, 2.5),
    minWidth: 335,

    "&:hover": {
      backgroundColor: "#dddddd"
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 326,
      margin: theme.spacing(0, 0, 2),
      fontSize: 14,
      minWidth: "none"
    }
  }
}));

const JoinUsSection = () => {
  const classes = useStyles();

  const subtitle =
    "A Next Generation company combining the best of culture, sports, tech & teamwork";

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <Text className={classes.heading} type="small">
          Join Us
        </Text>
        <Text className={classes.text} type="subtitle">
          {subtitle}
        </Text>
      </div>
      <div className={classes.buttons}>
        <ActiveButton
          target="blank"
          href={externalLinks.careers}
          className={classes.button}
        >
          Join Us
        </ActiveButton>
      </div>
    </section>
  );
};

export default JoinUsSection;
