import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";

// Components
import NavList from "./NavList";
import NavListMobile from "./NavListMobile";
import { values } from "../../styles/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    margin: 0,
    width: "100%",
    boxShadow: ({ sticky }) => sticky && values.boxShadow
  }
}));

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

const Navbar = () => {
  const [sticky, setSticky] = useState(false);
  const classes = useStyles({ sticky });

  useScrollToTop();

  const handleScroll = () => setSticky(window.scrollY > 90);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar className={classes.root} color="inherit" position="fixed">
      <Hidden smDown>
        <NavList sticky={sticky} />
      </Hidden>
      <Hidden mdUp>
        <NavListMobile sticky={sticky} />
      </Hidden>
    </AppBar>
  );
};

export default Navbar;
