import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// FontAwesome
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Icons
import buffBlue from "../../../../assets/logos/buffBlue.svg";
import map1 from "../../../../assets/images/map1.png";
import map2 from "../../../../assets/images/map2.png";
import map3 from "../../../../assets/images/map3.png";

// Styles
import { values } from "../../../../styles/styles";
import colors from "../../../../styles/colors";

// Components
import Text from "../../../../components/Text";
import GoogleMap from "../../../../components/GoogleMap";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: values.boxShadow3,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    height: 968,
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      height: 645
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto"
    }
  },
  logo: {
    position: "absolute",
    top: 50,
    left: 77,
    zIndex: 99,

    [theme.breakpoints.down("md")]: {
      top: 34,
      left: 51
    },

    [theme.breakpoints.down("sm")]: {
      top: 18,
      left: 27,
      width: 63
    }
  },
  map: {
    width: "62.29vw",
    height: "100%",
    boxShadow: values.boxShadow4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      height: "81vw",
      width: "100%"
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(15, 10, 7),

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7)
    }
  },
  icon: {
    color: colors.rhino,
    fontSize: 56,
    marginBottom: theme.spacing(2.5),

    [theme.breakpoints.down("md")]: {
      fontSize: 33,
      marginBottom: theme.spacing(1)
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1.5),

    [theme.breakpoints.up("md")]: {
      fontSize: 24
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginBottom: theme.spacing(1)
    }
  },
  addressWrapper: {
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1)
    }
  },
  address: {
    lineHeight: 1.2,

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 22
    }
  },
  subtitle: {
    fontSize: 36,
    marginBottom: theme.spacing(8),
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(7),
      fontSize: 21
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 20
    }
  },
  list: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      overflow: "hidden"
    }
  },
  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: theme.spacing(7)
  },
  location: {
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("md")]: {
      height: "7vw"
    },
    [theme.breakpoints.down("sm")]: {
      height: "18vw"
    }
  },
  text: {
    width: 130,
    fontSize: 26,

    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      width: 87
    },

    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      width: 74
    }
  },
  vertline: {
    height: "100%",
    width: 1,
    backgroundColor: colors.alto,
    opacity: 0.7,
    margin: theme.spacing(0, 3),

    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 1)
    }
  }
}));

const JoinUsSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id="hq">
      <img className={classes.logo} src={buffBlue} alt="Headquarters" />
      <div className={classes.map}>
        <GoogleMap />
      </div>
      <div className={classes.contentWrapper}>
        <FontAwesomeIcon className={classes.icon} icon={faMapMarkerAlt} />
        <Text className={classes.title} type="title">
          Headquarters
        </Text>
        <div className={classes.addressWrapper}>
          <Text type="description" className={classes.address}>
            10 York Road,
          </Text>
          <Text type="description" className={classes.address}>
            London SE1 7ND, UK
          </Text>
        </div>
        <Text type="subtitle" className={classes.subtitle}>
          + Worldwide Team
        </Text>
        <div className={classes.list}>
          <div className={classes.col}>
            <img className={classes.location} src={map1} alt="Latin America" />
            <Text type="description" className={classes.text}>
              Argentina
            </Text>
          </div>
          <div className={classes.vertline} />
          <div className={classes.col}>
            <img className={classes.location} src={map2} alt="Europe" />
            <Text type="description" className={classes.text}>
              Greece
            </Text>
            <Text type="description" className={classes.text}>
              Italy
            </Text>
            <Text type="description" className={classes.text}>
              Portugal
            </Text>
            <Text type="description" className={classes.text}>
              UK
            </Text>
          </div>
          <div className={classes.vertline} />
          <div className={classes.col}>
            <img className={classes.location} src={map3} alt="Asia" />
            <Text type="description" className={classes.text}>
              China
            </Text>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinUsSection;
