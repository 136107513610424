import React, { useState } from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Hidden from "@material-ui/core/Hidden";
import InputBase from "@material-ui/core/InputBase";

// Images
import check from "../../../../assets/icons/check.svg";
import phoneBasketMobile from "../../../../assets/images/phone_basket_mobile_x2.png";
import phones from "../../../../assets/images/phones.png";

// Config
import { demoUrl, formspreeAction } from "../../../../routes/routesConfig";
import { getButtonClasses } from "../../../../components/ActiveButton/ActiveButton";
import colors from "../../../../styles/colors";

// Components
import ActiveButton from "../../../../components/ActiveButton";
import Text from "../../../../components/Text";
import Link from "../../../../components/Link";

const useStyles = makeStyles(theme => ({
  detailWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: theme.spacing(10, 0, 8, 23),

    [theme.breakpoints.down("lg")]: {
      paddingLeft: `9.583vw`
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: `10.547vw`
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 0
    }
  },
  detailContent: {
    paddingTop: 106,
    display: "flex",
    flexDirection: "column",
    width: "50%",
    minWidth: 801,
    marginRight: theme.spacing(5),

    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingTop: 50,
      maxWidth: 570,
      minWidth: "auto"
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: 50
    }
  },
  subtitle: {
    paddingLeft: theme.spacing(2.5)
  },
  detailText: {
    paddingLeft: theme.spacing(2.5),
    lineHeight: 1,

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: "0 auto",
      width: "75vw",
      maxWidth: 420,
      lineHeight: 1.5
    }
  },
  requestWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: theme.spacing(7.5, 0)
  },
  input: {
    padding: theme.spacing(0, 5),
    border: "1px solid",
    marginRight: theme.spacing(2.5),
    borderColor: colors.alto,
    fontSize: 32,
    lineHeight: 1,
    borderRadius: "42px",

    [theme.breakpoints.down("md")]: {
      fontSize: 21
    }
  },
  detailImages: {
    width: "50%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  phones: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 99,
    width: "100%",
    marginRight: -14
  },
  basketMobile: {
    width: "100%",
    marginBottom: theme.spacing(1.5)
  },
  requestMobile: {
    margin: "0 auto",
    display: "flex"
  },
  activeButton: {
    ...getButtonClasses(theme).root,
    textDecoration: "none",
    color: "white",
    backgroundColor: colors.violetRed,

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      minWidth: "none"
    }
  },
  submit: {
    minWidth: "340px"
  },
  submitSuccess: {
    backgroundColor: `${colors.turquoise} !important`,
    color: "white !important"
  },
  successIcon: {
    margin: theme.spacing(0, 0, 0, 2)
  }
}));

const DetailWrapper = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = e => {
    e.preventDefault();
    setLoading(true);

    fetch(formspreeAction, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
      setLoading(false);
      setSuccess(true);
    });
  };

  const handleChange = ({ target: { value } }) => {
    setEmail(value);
  };

  return (
    <div className={classes.detailWrapper}>
      <div className={classes.detailContent}>
        <Hidden smDown>
          <Text type="subtitle" className={classes.subtitle}>
            Powerful social platform that makes a game out of live sports
          </Text>
          <form onSubmit={onSubmit} className={classes.requestWrapper}>
            <InputBase
              className={classes.input}
              disabled={loading}
              name="email"
              onChange={handleChange}
              placeholder="Your email address"
              required
              type="email"
              value={email}
            />
            <ActiveButton
              type="submit"
              disabled={loading || success}
              className={clsx(classes.submit, {
                [classes.submitSuccess]: success
              })}
            >
              {loading && <CircularProgress size={32} />}
              {!loading &&
                (success ? (
                  <>
                    Done!
                    <img src={check} alt="" className={classes.successIcon} />
                  </>
                ) : (
                  "Request a Demo"
                ))}
            </ActiveButton>
          </form>
        </Hidden>
        <Text type="description" className={classes.detailText}>
          <b>BUFF</b> works with leading broadcasters and rights holders to take
          fan and viewer experiences to the next level.
        </Text>
      </div>
      <div className={classes.detailImages}>
        <Hidden smDown>
          <img src={phones} className={classes.phones} alt="Sports" />
        </Hidden>
        <Hidden mdUp>
          <img
            src={phoneBasketMobile}
            className={classes.basketMobile}
            alt="Sports"
          />
        </Hidden>
      </div>
      <Hidden mdUp>
        <div className={classes.requestMobile}>
          <Link
            scroll={el =>
              el.scrollIntoView({
                behavior: "smooth",
                block: "start"
              })
            }
            smooth
            to={demoUrl}
            className={classes.activeButton}
          >
            Request A Demo
          </Link>
        </div>
      </Hidden>
    </div>
  );
};

export default DetailWrapper;
