import React from "react";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";

// SOURCES

// External link validation
// https://github.com/ReactTraining/react-router/issues/1147#issuecomment-362964577

// Material UI componsition guide for React.forwardRef():
// https://material-ui.com/guides/composition/

const Link = React.forwardRef(
  ({ to, children, smooth, scroll, ...props }, ref) => {
    // It is a simple element with nothing to link to
    if (!to) return <span {...props}>{children}</span>;

    // It is intended to be an external link
    if (/^https?:\/\//.test(to))
      return (
        <a href={to} {...props}>
          {children}
        </a>
      );

    // Finally, it is an internal link
    return (
      <HashLink
        innerRef={ref}
        scroll={scroll}
        smooth={smooth}
        to={to}
        {...props}
      >
        {children}
      </HashLink>
    );
  }
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  scroll: PropTypes.func,
  smooth: PropTypes.bool,
  to: PropTypes.string.isRequired
};

Link.defaultProps = {
  smooth: false,
  scroll: () => {}
};

export default Link;
