import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Config
import { featuresUrl } from "../../../../routes/routesConfig";
import { getButtonClasses } from "../../../../components/ActiveButton/ActiveButton";
import colors from "../../../../styles/colors";

// Components
import Link from "../../../../components/Link";
import Sponsors from "../../../../components/Sponsors";
import Text from "../../../../components/Text";

// Sections
import AboutUs from "./AboutUs";
import HowBuffWorks from "./HowBuffWorks";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.whiteLilac,
    paddingTop: theme.spacing(21),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(10)
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(5)
    }
  },
  spanHeading: {
    color: colors.violetRed,
    paddingBottom: theme.spacing(1.5)
  },
  subtitle: {
    textAlign: "center",
    width: "40.52vw",
    margin: `0 auto ${theme.spacing(7.5)}px`,

    [theme.breakpoints.down("sm")]: {
      width: "65.21vw",
      marginBottom: theme.spacing(3)
    }
  },
  activeButton: {
    ...getButtonClasses(theme).root,
    textDecoration: "none",
    color: "white",
    backgroundColor: colors.violetRed,

    [theme.breakpoints.down("sm")]: {
      width: "78.5%",
      maxWidth: 325,
      marginBottom: theme.spacing(11),
      fontSize: 16
    }
  }
}));

const AboutSection = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Text type="small" className={classes.spanHeading}>
        How <b>BUFF</b> Works
      </Text>
      <Text type="subtitle" className={classes.subtitle}>
        Our Platform Enriches Your Content And Engages Your Audience
      </Text>
      <HowBuffWorks />
      <Text type="subtitle" className={classes.subtitle}>
        YOUR Live Stream ready solution
      </Text>
      <Link
        scroll={el =>
          el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          })
        }
        smooth
        to={featuresUrl}
        className={classes.activeButton}
      >
        More Features
      </Link>
      <Hidden smDown>
        <Sponsors />
      </Hidden>
      <AboutUs />
    </section>
  );
};

export default AboutSection;
