import { Redirect } from "react-router-dom";
import CompanyPage from "../containers/CompanyPage";
import OverviewPage from "../containers/OverviewPage";
import ProductPage from "../containers/ProductPage";

// Formspree
export const formspreeAction = "https://formspree.io/mbjgnvll";

export const hqUrl = "/company#hq";
export const demoUrl = "/company#stay";
export const featuresUrl = "/product#features";

export const externalLinks = {
  careers: "https://angel.co/company/buff1",
  terms:
    "https://firebasestorage.googleapis.com/v0/b/buffuptv-89e56.appspot.com/o/Terms%20and%20Conditions%20Sport%20Buff.pdf?alt=media&token=747da129-8aab-4d18-a1d8-310132a8423e",
  privacy:
    "https://firebasestorage.googleapis.com/v0/b/buffuptv-89e56.appspot.com/o/Privacy%20Policy%20for%20Sport%20Buff%20and%20Buff%20Up%20Limited.pdf?alt=media&token=e25e37a4-49f2-44a8-ae1e-955c0551d827",
  facebook: "https://www.facebook.com/",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
  linkedin: "https://www.linkedin.com/",
  appstore: "https://www.apple.com/ios/app-store/",
  more: "https://www.google.com",
};

export const routes = [
  {
    component: OverviewPage,
    id: "home",
    title: "",
    path: "/",
    isExact: true,
  },
  {
    component: ProductPage,
    id: "product",
    title: "Product",
    path: "/product",
    isExact: true,
  },
  {
    component: CompanyPage,
    id: "company",
    title: "Company",
    path: "/company",
    isExact: true,
  },
  {
    component: Redirect,
    id: "404",
    props: {
      to: "/",
    },
    isExact: true,
  },
];

export const rows = [
  {
    key: "Platform",
    title: "Platform",
    nav: "Product",
    basePath: "/product",
    items: [
      {
        id: "Platform",
        text: "Platform Features",
        to: "#features",
        block: "start",
      },
      {
        id: "live",
        text: "Live Questions + Polls",
        to: "#live",
        block: "center",
      },
      {
        id: "Ratings",
        text: "Ratings + Rankings",
        to: "#ratings",
        block: "start",
      },
      {
        id: "gaming",
        text: "Gamification + Rewards",
        to: "#ratings",
        block: "start",
      },
      {
        id: "Data",
        text: "Trend Data + Audience Insights",
        to: "#data",
        block: "start",
      },
    ],
  },
  {
    key: "Company",
    title: "Company",
    basePath: "/company",
    items: [
      {
        id: "About",
        text: "About Us",
        to: "#about",
        block: "start",
      },
      {
        id: "Privacy",
        text: "Privacy",
        to: externalLinks.privacy,
        external: true,
      },
      {
        id: "Terms",
        text: "Terms",
        to: externalLinks.terms,
        external: true,
      },
      {
        id: "Stay",
        text: "Stay In Touch",
        to: "#stay"
      },
      {
        id: "SDK",
        text: "Ask For Our SDK Integration",
        to: "#stay",
        block: "center",
      },
    ],
  },
];
