import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import youtube from "../../assets/icons/youtube.svg";
import colors from "../../styles/colors";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    margin: theme.spacing(0, 5),
    width: 380,
    order: 1,

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 2),
      width: "auto",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  first: {
    [theme.breakpoints.down("sm")]: {
      order: 0
    }
  },
  icon: {
    marginBottom: theme.spacing(5),

    [theme.breakpoints.down("md")]: {
      height: 31,
      marginBottom: theme.spacing(3)
    },

    [theme.breakpoints.down("sm")]: {
      width: 20,
      margin: theme.spacing(0, 2, 0, 0)
    }
  },
  content: {
    [theme.breakpoints.down("md")]: {
      lineHeight: 2
    },

    "& span": {
      color: colors.violetRed
    }
  }
}));

const YouTubeItem = ({ heading, content, index }) => {
  const classes = useStyles();
  return (
    <article
      className={clsx(classes.root, {
        [classes.first]: index === 1
      })}
    >
      <img className={classes.icon} src={youtube} alt="Feature" />
      <Text align="center" type="description">
        {heading}
      </Text>
      <Hidden smDown>
        <Text
          align="center"
          type="body"
          dangerouslySetInnerHTML={{ __html: content }}
          className={classes.content}
        />
      </Hidden>
    </article>
  );
};

YouTubeItem.propTypes = {
  heading: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired
};

export default YouTubeItem;
