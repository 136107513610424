import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Config
import { demoUrl } from "../../../../routes/routesConfig";
import { values, rawClasses } from "../../../../styles/styles";
import colors from "../../../../styles/colors";

// Components
import { getButtonClasses } from "../../../../components/ActiveButton";
import Link from "../../../../components/Link";
import Text from "../../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    ...rawClasses.highlightSection,
    order: 3,

    [theme.breakpoints.down("md")]: {
      ...rawClasses.highlightSectionMd
    },

    [theme.breakpoints.down("sm")]: {
      order: 4,
      padding: theme.spacing(8.5, 0)
    }
  },
  content: rawClasses.highlightContent,
  heading: {
    marginBottom: theme.spacing(5),
    textTransform: "capitalize",

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2.75)
    }
  },
  text: {
    color: "inherit",
    textTransform: "inherit",
    marginBottom: theme.spacing(5),
    fontWeight: theme.typography.fontWeightRegular,

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    },

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2.75),
      maxWidth: 327,
      width: "79vw",
      fontSize: 26
    }
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(2.5),
    width: "100%"
  },
  button: {
    ...getButtonClasses(theme).root,
    backgroundColor: "white",
    color: colors.violetRed,
    boxShadow: values.boxShadow,
    margin: theme.spacing(0, 2.5),
    textDecoration: "none",
    minWidth: 335,

    "&:hover": {
      backgroundColor: "#dddddd"
    },

    [theme.breakpoints.down("md")]: {
      ...getButtonClasses(theme).root,
      minWidth: 278
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: 326,
      margin: theme.spacing(0, 0, 2),
      fontSize: 14,
      minWidth: "none"
    }
  }
}));

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <Text className={classes.heading} type="small">
          How it works
        </Text>
        <Text className={classes.text} type="subtitle">
          Ready to try it for yourself?
        </Text>
      </div>
      <div className={classes.buttons}>
        <Link
          scroll={el =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start"
            })
          }
          smooth
          to={demoUrl}
          className={classes.button}
        >
          Request A Demo
        </Link>
      </div>
    </section>
  );
};

export default HowItWorks;
