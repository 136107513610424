import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

// Components
import { values } from "../../styles/styles";
import Text from "../Text";

const useStyles = makeStyles(theme => ({
  card: {
    padding: theme.spacing(13, 6, 0),
    display: "flex",
    flexDirection: "column",
    boxShadow: values.boxShadow,
    width: 458,
    borderRadius: "13px",
    position: "relative",
    overflow: "initial",

    "&:last-child": {
      marginRight: 30
    },

    [theme.breakpoints.down("lg")]: {
      width: "30%",
      padding: theme.spacing(7, 4, 0)
    },

    [theme.breakpoints.down("md")]: {
      width: "30%",

      "&:last-child": {
        marginRight: "inherit"
      }
    },

    [theme.breakpoints.down("sm")]: {
      width: "78.5%",
      maxWidth: 325,
      backgroundColor: "transparent",
      boxShadow: "none",
      marginBottom: theme.spacing(2.5),
      margin: "0 auto",
      alignItems: "center",

      "&:last-child": {
        margin: "0 auto"
      }
    }
  },
  heading: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    lineHeight: 1.5,

    [theme.breakpoints.down("sm")]: {
      fontWeight: theme.typography.fontWeightBold,
      width: "90%",
      marginBottom: theme.spacing(1)
    }
  },
  body: {
    textAlign: "center",
    marginBottom: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3)
    }
  },
  icon: {
    top: 0,
    left: 40,
    transform: "translateY(-50%)",
    position: "absolute",

    [theme.breakpoints.down("lg")]: {
      width: "4.6vw"
    },

    [theme.breakpoints.down("md")]: {
      width: "5vw"
    },

    [theme.breakpoints.down("sm")]: {
      top: 0,
      left: 0,
      transform: "none",
      width: 50
    }
  }
}));

const CardComponent = ({ alt, body, children, heading, src }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <img src={src} alt={alt} className={classes.icon} />
      <Text type="description" className={classes.heading}>
        {heading}
      </Text>
      <Text type="body" className={classes.body}>
        {body}
      </Text>
      {children}
    </Card>
  );
};

CardComponent.propTypes = {
  alt: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  src: PropTypes.node.isRequired
};

export default CardComponent;
