import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text from "../../../../components/Text";
import YouTubeItem from "../../../../components/YouTubeItem";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(16, 0, 19),
    margin: "0 9.17vw",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(11, 0, 17),
      flexDirection: "column"
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 0, 9),
      flexDirection: "column"
    }
  },
  title: {
    marginBottom: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5)
    }
  },
  itemList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  }
}));

const items = [
  {
    heading: "Revitalise VOD content",
    content: `Yesterdays match doesn’t have to be yesterdays news, <span>drive fresh</span> engagement over the <span>replays</span> and talking points.`,
    key: "1"
  },
  {
    heading: "Power up non-Live",
    content: `<span>Buff</span> takes <span> match visualizations</span> to the next level as fans engage over the action`,
    key: "2"
  },
  {
    heading: "Localise your engagements",
    content: `Adapt the <span>Buff</span> content to <span>different audiences</span>, in different regions, with different questions, <span>sponsors and prizes.</span>`,
    key: "3"
  }
];

const AndMuchMore = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Text type="title" className={classes.title}>
        And much more…
      </Text>
      <div className={classes.itemList}>
        {items.map(({ key, ...item }, index) => (
          <YouTubeItem key={key} {...item} index={index} />
        ))}
      </div>
    </div>
  );
};

export default AndMuchMore;
