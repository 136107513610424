import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Config
import { demoUrl } from "../../../../routes/routesConfig";
import { getButtonClasses } from "../../../../components/ActiveButton/ActiveButton";
import colors from "../../../../styles/colors";

// Images
import smallHero from "../../../../assets/images/hero_img_small.png";
import logo from "../../../../assets/logos/BuffLogo.svg";

// Components
import Text from "../../../../components/Text";
import Link from "../../../../components/Link";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(7, 0, 18),
    order: 0,

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(10, 0, 3.5),
      flexDirection: "column",
      alignItems: "center"
    }
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: 570,
    marginLeft: "10.57vw",

    [theme.breakpoints.down("sm")]: {
      margin: 0,
      alignItems: "center",
      width: "auto",
      maxWidth: 300
    }
  },
  description: {
    margin: theme.spacing(0, 0, 4),

    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 0, 3),
      maxWidth: 300
    }
  },
  heroContainer: {
    padding: theme.spacing(0, 7, 0, 5)
  },
  hero: {
    width: "100%"
  },
  heroMobile: {
    width: "100vw",
    padding: theme.spacing(0, 1)
  },
  button: {
    ...getButtonClasses(theme).root,
    textDecoration: "none",
    minWidth: 335,
    color: "white",
    backgroundColor: colors.violetRed,

    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      minWidth: "none"
    }
  },
  logo: {
    width: 116,
    marginBottom: theme.spacing(4),
    margin: "0 auto"
  }
}));

const BroadcastSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Hidden mdUp>
        <img className={classes.logo} src={logo} alt="BUFF" />
      </Hidden>
      <div className={classes.heading}>
        <Text type="title">This Is Your Broadcast Supercharged</Text>
        <Text type="description" className={classes.description}>
          Make your broadcast relevant for the next generation audience with the
          BUFF platform
        </Text>
        <Hidden mdUp>
          <img className={classes.heroMobile} src={smallHero} alt="Broadcast" />
        </Hidden>
        <Link
          scroll={el =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start"
            })
          }
          smooth
          to={demoUrl}
          className={classes.button}
        >
          Request A Demo
        </Link>
      </div>
      <Hidden smDown>
        <div className={classes.heroContainer}>
          <img className={classes.hero} src={smallHero} alt="Broadcast" />
        </div>
      </Hidden>
    </section>
  );
};

export default BroadcastSection;
