import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Images
import audience from "../../../../assets/images/img_audience.png";
import audienceMobile from "../../../../assets/images/img_audience_mobile_x2.png";
import ratings from "../../../../assets/images/img_ratings.png";

// Components
import FeatureItem from "../../../../components/FeatureItem";

const useStyles = makeStyles(theme => ({
  spacingWhite: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(0, 0, 10),
    paddingTop: "29.16vw",

    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      paddingBottom: theme.spacing(9)
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(4, 0, 5)
    }
  },
  greyBackground: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: theme.spacing(21, 0, 12),

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(14, 0, 5.5)
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 0, 0),
      flexDirection: "column",
      backgroundColor: "white",
      alignItems: "flex-end",
      overflow: "hidden"
    }
  },
  whiteBackground: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "white",
    padding: theme.spacing(21, 0, 55),
    position: "relative",

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(12, 0, 32)
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "60vw 0 0"
    }
  },
  instantAudience: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(12)
    }
  },
  audiencePlay: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(6)
    }
  },
  happierViewers: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(18)
    }
  },
  audience: {
    width: "82.81vw",
    margin: "0 auto",
    marginBottom: theme.spacing(16),

    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(12.5)
    }
  },
  audienceMobile: {
    width: "100vw",
    paddingLeft: "8.69vw",
    marginRight: "-2.1vw",
    marginBottom: theme.spacing(5)
  },
  ratings: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "53.80vw",

    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      top: -32,
      bottom: "initial",
      paddingRight: "12vw"
    }
  }
}));

const Features = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const itemProps = responsiveAlign => ({
    feature: {
      align: matches ? responsiveAlign : "right",
      heading: "Features"
    },
    benefit: {
      align: matches ? responsiveAlign : "left",
      heading: "Benefits"
    }
  });

  return (
    <>
      <div className={classes.spacingWhite} id="live">
        <FeatureItem
          {...itemProps("left").feature}
          title="Live questions to audience"
          subtitle="Polls, Predictions, Quiz, Opinion, Ratings"
          description="Live audience feedback on all parts of the broadcast, creating a new way to experience sports"
        />
        <FeatureItem
          {...itemProps("left").benefit}
          className={classes.instantAudience}
          title="Instant Audience Engagement"
          subtitle="Audience play along through the broadcast"
          description="Audience feels part of the show, stays longer, more engaged and keeps coming back"
        />
      </div>
      <div className={classes.greyBackground} id="ratings">
        <Hidden mdUp>
          <img
            className={classes.audienceMobile}
            src={audienceMobile}
            alt="Audience"
          />
        </Hidden>
        <FeatureItem
          {...itemProps("right").feature}
          title="Ratings, Rankings, Gamification, Rewards"
          subtitle="Audience want to play with their friends on your broadcast"
          description="Audience prove they can top the rankings, beat their friends, and collect the rewards"
        />
        <FeatureItem
          {...itemProps("right").benefit}
          className={classes.audiencePlay}
          title="Audience Play, Stay, and Spend"
          subtitle="Reward and redemption drives audience engagement and retention"
          description="Combined with unique sponsor opportunities for engagement over content"
        />
      </div>
      <Hidden smDown>
        <img className={classes.audience} src={audience} alt="Rankings" />
      </Hidden>
      <div className={classes.whiteBackground} id="data">
        <FeatureItem
          {...itemProps("left").feature}
          title="Audience Data and Insights"
          subtitle="trend data of audience behaviour"
          description="Get real time data on audience behaviour and metrics across streams, game weeks and whole seasons"
        />
        <FeatureItem
          {...itemProps("left").benefit}
          className={classes.happierViewers}
          title="Happier viewers that keep coming back - with their friends"
          subtitle="Understand the best ways to keep your audience on broadcast"
          description="Viewers stay longer, bring their friends, spend more and give permission based data for further engagement. They appreciate that you understand them"
        />
        <img className={classes.ratings} src={ratings} alt="Audience" />
      </div>
    </>
  );
};

export default Features;
