import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: colors.rhino,
    fontSize: 56,
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      marginBottom: theme.spacing(1.5)
    }
  },
  subtitle: {
    fontSize: 36,
    color: colors.rhino,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",

    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    }
  },
  description: {
    color: colors.mineShaft,
    fontSize: 22,
    lineHeight: 2,

    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    }
  },
  body: {
    color: colors.dustyGray,
    fontSize: 18,
    fontFamily: ["Open Sans", "sans-serif"].join(","),

    [theme.breakpoints.down("md")]: {
      fontSize: 13
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  body2: {
    color: colors.fullBlack,
    fontSize: 16,

    [theme.breakpoints.down("md")]: {
      fontSize: 12
    },

    [theme.breakpoints.down("sm")]: {
      lineHeight: "2",
      fontSize: 14
    }
  },
  small: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    textTransform: "capitalize",

    [theme.breakpoints.down("md")]: {
      fontSize: 11
    }
  },
  label: {
    fontSize: 15,
    fontFamily: ["Gibson SemiBold", "sans-serif"].join(","),
    color: colors.lynch,
    textAlign: "left",

    [theme.breakpoints.down("md")]: {
      fontSize: 11
    }
  }
}));

const variants = {
  title: "h2",
  subtitle: "h3",
  description: "body1",
  body: "body1",
  body2: "body2",
  small: "body1",
  label: "caption"
};

const Text = ({ type, align, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Typography
      classes={{ root: classes[type] }}
      variant={variants[type]}
      align={align || (matches ? "center" : "inherit")}
      {...props}
    />
  );
};

Text.propTypes = {
  type: PropTypes.oneOf(Object.keys(variants)).isRequired,
  align: PropTypes.string
};

Text.defaultProps = {
  align: ""
};

export default Text;
