import React from 'react'

// Material
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'

// Icons
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Config
import colors from '../../styles/colors'
import { rows, hqUrl } from '../../routes/routesConfig'

// Components
import BuffLogo from '../../components/BuffLogo'
import Link from '../../components/Link'
import Text from '../../components/Text'
// import Social from "../../components/Social/Social";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(13, 0, 7),
    margin: '0 auto',
    width: '84.375vw',
    maxWidth: 1920,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: theme.spacing(5.5, 0, 8.5)
    }
  },
  row: {
    width: '22%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 150,
    alignItems: 'flex-start',

    '&:first-child': {
      minWidth: 'auto',
      width: '34%',
      justifyContent: 'space-between'
    },

    '&:last-child': {
      justifyContent: 'space-between'
    },

    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        width: '100%'
      }
    }
  },
  rowTitle: {
    color: colors.black,
    textTransform: 'uppercase',
    opacity: 0.5,
    marginBottom: theme.spacing(3),
    fontWeight: theme.typography.fontWeightBold,

    [theme.breakpoints.down('md')]: {
      fontSize: 12
    }
  },
  linkTitle: {
    display: 'flex',
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: colors.black,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  item: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',

    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  linkItem: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    color: colors.black,

    '&:hover': {
      textDecoration: 'underline'
    }
  },
  logo: {
    marginBottom: theme.spacing(35)
  },
  heart: {
    color: colors.red
  },
  address: {
    lineHeight: '2rem',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4.5, 0, 0)
    }
  },
  socialRoot: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  socialButton: {
    margin: theme.spacing(0, 1.5, 0, 0),
    width: 45,
    height: 45,
    overflow: 'hidden',
    padding: 0,
    borderRadius: '100%'
  },
  socialIcon: {
    width: 45
  },
  socialLink: {
    width: 16,
    height: 16,
    margin: theme.spacing(0, 1, 3),
    fontSize: 16,
    color: colors.black
  }
}))

const Footer = () => {
  const classes = useStyles()
  // const socialClasses = {
  //   root: classes.socialRoot,
  //   button: classes.socialButton,
  //   image: classes.socialIcon
  // };

  return (
    <footer className={classes.root}>
      <div className={classes.row} direction="column" spacing={35}>
        <Hidden smDown>
          <BuffLogo/>
        </Hidden>

        <div className={classes.item}>
          {/* <Hidden mdUp>
            <div className={classes.socialRoot}>
              <Link
                target="blank"
                className={classes.socialLink}
                to={externalLinks.instagram}
              >
                <FontAwesomeIcon icon={faInstagram} />
              </Link>
              <Link
                target="blank"
                className={classes.socialLink}
                to={externalLinks.facebook}
              >
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
              <Link
                target="blank"
                className={classes.socialLink}
                to={externalLinks.twitter}
              >
                <FontAwesomeIcon icon={faTwitter} />
              </Link>
            </div>
          </Hidden> */}

          <Hidden smDown>
            <Text type="body2">
              Made with{' '}
              <FontAwesomeIcon className={classes.heart} icon={faHeart}/>
            </Text>
            <Text type="body2">by sports fans of all backgrounds.</Text>
            <Text type="body2">© Copyright Buff Up Ltd.</Text>
          </Hidden>

          <Hidden mdUp>
            <Text type="body2">
              Made with{' '}
              <FontAwesomeIcon className={classes.heart} icon={faHeart}/> by
              people of all backgrounds.
            </Text>
            <Text type="body2">© Copyright Buff Up Ltd.</Text>
            <Text type="body2" className={classes.address}>
              <FontAwesomeIcon icon={faMapMarkerAlt}/> 10 York Road, London SE1
              7ND, UK
            </Text>
          </Hidden>
        </div>
      </div>

      <Hidden smDown>
        {rows.map(({ title, key, items, basePath }) => (
          <div key={key} className={classes.row}>
            <Link smooth className={classes.linkTitle} to={basePath}>
              <Text type="small" className={classes.rowTitle}>
                {title}
              </Text>
            </Link>
            {items.map(({ block, external, id, text, to }) => (
              <Link
                smooth
                className={classes.link}
                key={id}
                to={external ? to : `${basePath}${to}`}
                scroll={el =>
                  el.scrollIntoView({
                    behavior: 'smooth',
                    block: block || 'start'
                  })
                }
              >
                <Text type="body2" className={classes.item}>
                  {text}
                </Text>
              </Link>
            ))}
          </div>
        ))}

        <div className={classes.row}>
          <div>
            <Text type="small" className={classes.rowTitle}>
              HQ
            </Text>
            <div className={classes.item}>
              <Link
                smooth
                className={classes.link}
                to={hqUrl}
                scroll={el =>
                  el.scrollIntoView({ behavior: 'instant', block: 'center' })
                }
              >
                <Text type="body2" className={classes.address}>
                  10 York Road,
                </Text>
                <Text type="body2" className={classes.address}>
                  London SE1 7ND, UK
                </Text>
              </Link>
            </div>
          </div>
          {/* <Social classes={socialClasses} /> */}
        </div>
      </Hidden>
    </footer>
  )
}

export default Footer
