import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";

// Icons
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// Components
import Text from "../Text";
import Link from "../Link";

// Styles
import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: theme.spacing(10),
    width: 120,

    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(5)
    },

    [theme.breakpoints.down("md")]: {
      width: 100,
      marginLeft: theme.spacing(2)
    }
  },
  button: {
    textTransform: "inherit",
    borderRadius: "42px",
    fontSize: 16,
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,

    [theme.breakpoints.down("md")]: {
      marginTop: 0,
      fontSize: 14
    }
  },
  icon: {
    marginLeft: theme.spacing(1),
    color: colors.mischka,
    transform: ({ open }) => open && "rotate(180deg)",
    transition: "transform .2s ease-in-out"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    height: 0,
    overflow: "hidden",
    transition: "height .2s ease-in-out"
  },
  toggled: {
    height: ({ length }) => 80 * length,

    [theme.breakpoints.down("md")]: {
      height: ({ length }) => 66 * length
    }
  },
  content: {
    padding: theme.spacing(1, 0),
    display: "flex",
    flexDirection: "column"
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: colors.black,

    "&:hover": {
      textDecoration: "underline"
    }
  },
  item: {
    margin: theme.spacing(2, 0),
    display: "flex",
    flexDirection: "column",
    textDecoration: "none"
  }
}));

const Dropdown = ({ title, items, basePath }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open, length: items.length });
  const { push } = useHistory();

  const toggleOpen = value => () => {
    setOpen(value);
  };

  return (
    <ClickAwayListener onClickAway={toggleOpen(false)}>
      <div
        className={classes.root}
        onMouseOver={toggleOpen(true)}
        onFocus={toggleOpen(true)}
        onMouseLeave={toggleOpen(false)}
      >
        <Button
          onClick={() => push(basePath)}
          className={classes.button}
          color="inherit"
        >
          {title}
          <FontAwesomeIcon className={classes.icon} icon={faChevronDown} />
        </Button>
        <div
          className={clsx(classes.details, {
            [classes.toggled]: open
          })}
        >
          <div className={classes.content}>
            {items.map(({ block, external, id, text, to }) => (
              <Link
                smooth
                onClick={toggleOpen(false)}
                className={classes.link}
                key={id}
                to={external ? basePath : `${basePath}${to}`}
                scroll={el =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: block || "start"
                  })
                }
              >
                <Text type="body2" className={classes.item}>
                  {text}
                </Text>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  basePath: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      to: PropTypes.string,
      block: PropTypes.string,
      external: PropTypes.bool
    })
  ).isRequired,
  title: PropTypes.node.isRequired
};

export default Dropdown;
