import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { rawClasses } from "../../styles/styles";
import {
  CultureSection,
  HeadquartersSection,
  JoinUsSection,
  LetsTalkSection
} from "./sections";

const useStyles = makeStyles(theme => ({
  root: {
    ...rawClasses.main,
    [theme.breakpoints.down("md")]: {
      ...rawClasses.mainMd
    },
    [theme.breakpoints.down("sm")]: {
      ...rawClasses.mainMobile
    }
  }
}));

const CompanyPage = () => {
  const classes = useStyles();
  return (
    <main className={classes.root} id="about">
      <CultureSection />
      <JoinUsSection />
      <HeadquartersSection />
      <LetsTalkSection />
    </main>
  );
};

export default CompanyPage;
