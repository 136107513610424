import React from "react";
import PropTypes from "prop-types";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

// FontAwesome
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Config
import { externalLinks } from "../../routes/routesConfig";
import colors from "../../styles/colors";

// Components
import BuffLogo from "../../components/BuffLogo";
import Link from "../../components/Link";
import Text from "../../components/Text/Text";
import RouteItems from "./RouteItems";

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(0, 6),
    marginBottom: theme.spacing(7)
  },
  logo: {
    width: 85
  },
  closeButton: {
    backgroundColor: colors.violetRed,
    height: 24,
    width: 24
  },
  closeIcon: {
    color: "white",
    height: 14,
    width: 14
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  heading: {
    paddingLeft: theme.spacing(7),
    color: colors.violetRed,
    marginBottom: theme.spacing(3.5),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    position: "relative",

    "&:after": {
      content: `""`,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: 40,
      height: 1,
      backgroundColor: "black",
      opacity: 0.2
    }
  },
  social: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: theme.spacing(7),
    justifyContent: "flex-start"
  },
  link: {
    marginRight: theme.spacing(3),
    textDecoration: "none"
  }
}));

const DrawerContent = ({ onClose }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.toolbar}>
        <Link className={classes.logo} to="/">
          <BuffLogo width={85} />
        </Link>
        <ButtonBase onClick={onClose} className={classes.closeButton}>
          <FontAwesomeIcon className={classes.closeIcon} icon={faTimes} />
        </ButtonBase>
      </div>
      <div className={classes.content}>
        <Text type="small" className={classes.heading}>
          MENU
        </Text>
        <RouteItems onClick={onClose} />
        <Text type="small" className={classes.heading}>
          SOCIAL
        </Text>
        <nav className={classes.social}>
          <Link
            className={classes.link}
            target="blank"
            to={externalLinks.facebook}
          >
            <Text type="title">Fb</Text>
          </Link>
          <Link
            className={classes.link}
            target="blank"
            to={externalLinks.twitter}
          >
            <Text type="title">Tw</Text>
          </Link>
          <Link
            className={classes.link}
            target="blank"
            to={externalLinks.linkedin}
          >
            <Text type="title">In</Text>
          </Link>
          <Link
            className={classes.link}
            target="blank"
            to={externalLinks.instagram}
          >
            <Text type="title">Ig</Text>
          </Link>
        </nav>
      </div>
    </>
  );
};

DrawerContent.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DrawerContent;
