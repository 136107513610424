import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import logo from "../../assets/logos/BuffLogo.svg";

const useStyles = makeStyles(theme => ({
  image: {
    width: 182,
    transition: "all .2s ease-in-out",

    [theme.breakpoints.down("lg")]: {
      width: 150
    },

    [theme.breakpoints.down("md")]: {
      width: 120
    },

    [theme.breakpoints.down("sm")]: {
      width: 53,
      display: "block"
    }
  },
  sticky: {
    width: 120,

    [theme.breakpoints.down("lg")]: {
      width: 100
    },

    [theme.breakpoints.down("md")]: {
      width: 80
    },

    [theme.breakpoints.down("sm")]: {
      width: 53
    }
  },
  overrideWidth: ({ width }) => ({
    width
  })
}));

const BuffLogo = ({ width, sticky }) => {
  const classes = useStyles({ width: !sticky && width, sticky });

  return (
    <img
      className={clsx(classes.image, {
        [classes.sticky]: sticky,
        [classes.overrideWidth]: Boolean(width)
      })}
      src={logo}
      alt="BUFF"
    />
  );
};

BuffLogo.propTypes = {
  sticky: PropTypes.bool,
  width: PropTypes.number
};

BuffLogo.defaultProps = {
  sticky: false,
  width: null
};

export default BuffLogo;
