import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";

// Config
import { rows, demoUrl } from "../../routes/routesConfig";

// Components
import BuffLogo from "../../components/BuffLogo";
import Dropdown from "../../components/Dropdown";
import Link from "../../components/Link";
import { getButtonClasses } from "../../components/ActiveButton/ActiveButton";
import colors from "../../styles/colors";
// import Social from "../../components/Social";

const useStyles = makeStyles(theme => ({
  imageContainer: {
    marginRight: "auto",
    height: ({ sticky }) => (sticky ? 68 : "100%"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("md")]: {
      height: ({ sticky }) => sticky && 60
    }
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: "84.375vw",
    margin: "0 auto",
    padding: "0",
    maxWidth: 1920
  },
  navlist: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    width: 120,

    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(5)
    },

    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginTop: 0,
      width: 100
    }
  },
  button: {
    textTransform: "inherit",
    borderRadius: "42px",
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,

    [theme.breakpoints.down("md")]: {
      fontSize: 14
    }
  },
  activeButtonContainer: {
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      height: 33
    }
  },
  activeButton: {
    ...getButtonClasses(theme).root,
    color: "white",
    backgroundColor: colors.violetRed,
    textDecoration: "none",
    marginLeft: theme.spacing(5),
    textTransform: "inherit",
    borderRadius: "42px",
    padding: theme.spacing(1.5, 4.5),
    fontSize: 17,
    fontWeight: theme.typography.fontWeightMedium,

    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(3),
      padding: theme.spacing(0.75, 2.75),
      fontSize: 14
    }
  },
  social: {
    display: "flex",
    flexDirection: "column"
  },
  spacing: {
    height: ({ sticky }) => (sticky ? theme.spacing(2) : theme.spacing(7)),
    transition: "height .2s ease-in-out"
  },
  spacing2: {
    height: ({ sticky }) => (sticky ? theme.spacing(2) : theme.spacing(4)),
    transition: "height .2s ease-in-out"
  }
}));

const NavList = ({ sticky }) => {
  const classes = useStyles({ sticky });
  const { push } = useHistory();

  return (
    <>
      <div className={classes.spacing} />
      <Toolbar className={classes.toolbar}>
        <div className={classes.imageContainer}>
          <Link to="/">
            <BuffLogo sticky={sticky} />
          </Link>
        </div>
        <div className={classes.navlist}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={() => push("/")}
              className={classes.button}
            >
              Overview
            </Button>
          </div>

          {rows.map(({ nav, title, key, items, basePath }) => (
            <Dropdown
              items={items}
              key={key}
              height={items.length}
              title={nav || title}
              basePath={basePath}
            />
          ))}

          <div className={classes.activeButtonContainer}>
            <Link
              scroll={el =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start"
                })
              }
              smooth
              to={demoUrl}
              className={classes.activeButton}
            >
              Request A Demo
            </Link>
          </div>
        </div>
        {/* <Social /> */}
      </Toolbar>
      <div className={classes.spacing2} />
    </>
  );
};

NavList.propTypes = {
  sticky: PropTypes.bool.isRequired
};

export default NavList;
