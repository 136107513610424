import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Images
import buffHero from "../../../../assets/images/BuffHero.png";
import logo from "../../../../assets/logos/BuffLogo.svg";

// Components
import DetailWrapper from "./DetailWrapper";
import Text from "../../../../components/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "9vw",
    overflow: "hidden",

    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(10)
    },

    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5)
    }
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "61.25vw",
    margin: "0 auto 100px",
    textTransform: "capitalize",
    maxWidth: 800,

    [theme.breakpoints.down("sm")]: {
      width: "86vw",
      marginBottom: theme.spacing(2.5),
      alignItems: "center",
      maxWidth: 356
    }
  },
  description: {
    width: "72.2%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
      padding: theme.spacing(0, 1),
      fontSize: 16
    }
  },
  hero: {
    width: "91.615%",
    margin: "0 0 0 4.375vw",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      margin: "0 auto"
    }
  },
  logo: {
    width: 116,
    marginBottom: theme.spacing(4),
    margin: "0 auto"
  }
}));

const HeroSection = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Hidden mdUp>
        <img className={classes.logo} src={logo} alt="BUFF" />
      </Hidden>
      <div className={classes.heading}>
        <Text type="title">Your broadcast transformed into a live game</Text>
        <Text type="description" className={classes.description}>
          Our proprietary technology creates a live social game around the video
          content.
        </Text>
      </div>
      <img className={classes.hero} src={buffHero} alt="Broadcast" />
      <DetailWrapper />
    </section>
  );
};

export default HeroSection;
