import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Assets
import aboutus from "../../../../assets/images/aboutus.png";
import colors from "../../../../styles/colors";
import logo from "../../../../assets/logos/BuffLogo.svg";
import weareimg from "../../../../assets/images/weareimg.png";

// Components
import Link from "../../../../components/Link";
import Text from "../../../../components/Text";
import { getButtonClasses } from "../../../../components/ActiveButton/ActiveButton";
// import Social from "../../../../components/Social";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white"
    }
  },
  heading: {
    padding: theme.spacing(5, 0, 2.5),
    paddingLeft: "48.17vw",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: "0 auto",
      marginTop: theme.spacing(12),
      position: "relative",
      width: "100%"
    }
  },
  aboutus: {
    position: "absolute",
    left: "7.5%",
    top: 0,
    width: "36.93vw"
  },
  weareimg: {
    width: "100%"
  },
  logo: {
    width: 182,

    [theme.breakpoints.down("md")]: {
      width: 122
    },

    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2.5),
      minWidth: 70,
      width: "16.9vw"
    }
  },
  content: {
    backgroundColor: "white",
    padding: `${theme.spacing(5)}px 4.43vw ${theme.spacing(12)}px 48.17vw`,
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  },
  subtleTitle: {
    color: colors.mineShaft,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: "-2px",
    marginBottom: theme.spacing(4),
    opacity: 0.3,

    [theme.breakpoints.down("sm")]: {
      letterSpacing: "-1px",
      marginBottom: theme.spacing(2.5)
    }
  },
  subtitle: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      width: "83%",
      textAlign: "center"
    }
  },
  description: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      width: "83%",
      textAlign: "center",
      fontSize: 14
    }
  },
  socialRoot: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(0, 0, 7)
  },
  socialButton: {
    margin: theme.spacing(0, 2.25, 0, 0),
    width: 60,
    height: 60,
    overflow: "hidden",
    padding: 0,
    borderRadius: "100%"
  },
  socialIcon: {
    width: 60
  },
  activeButton: {
    ...getButtonClasses(theme).root,
    textDecoration: "none",
    color: "white",
    backgroundColor: colors.violetRed,
    marginBottom: theme.spacing(12),
    width: 416,

    [theme.breakpoints.down("md")]: {
      ...getButtonClasses(theme).root,
      width: 277
    },

    [theme.breakpoints.down("sm")]: {
      ...getButtonClasses(theme).root,
      minWidth: "auto",
      maxWidth: 325,
      width: "85vw",
      marginBottom: theme.spacing(6.5)
    }
  }
}));

const HowBuffWorks = () => {
  const classes = useStyles();
  // const socialClasses = {
  //   root: classes.socialRoot,
  //   button: classes.socialButton,
  //   image: classes.socialIcon
  // };

  return (
    <section className={classes.root}>
      <Hidden smDown>
        <img className={classes.aboutus} src={aboutus} alt="About Us" />
      </Hidden>
      <div className={classes.heading}>
        <img className={classes.logo} src={logo} alt="BUFF" />
        <Hidden mdUp>
          <img className={classes.weareimg} src={weareimg} alt="We Are" />
        </Hidden>
      </div>
      <div className={classes.content}>
        <Text type="subtitle" className={classes.subtleTitle}>
          We are <b>Buff</b>
        </Text>
        <Text type="subtitle" className={classes.subtitle}>
          A next generation company delivering the next generation audience
          experience platform
        </Text>
        <Text type="description" className={classes.description}>
          We’re looking for insanely talented folk with a massive passion for
          sports to join us as we revolutionise the live sports experience for
          fans around the globe. We have live jobs on Angellist but if you think
          you can bring something to our team that is not listed then get in
          touch.
        </Text>
        {/* <Social classes={socialClasses} /> */}
        <Link
          scroll={el =>
            el.scrollIntoView({
              behavior: "smooth",
              block: "start"
            })
          }
          smooth
          to="/company"
          className={classes.activeButton}
        >
          About Us
        </Link>
      </div>
    </section>
  );
};

export default HowBuffWorks;
