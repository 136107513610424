import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const getButtonClasses = theme => ({
  root: {
    textTransform: "capitalize",
    borderRadius: "42px",
    padding: theme.spacing(2, 4),
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      padding: theme.spacing(1.5, 2.5)
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    }
  }
});

const useButtonStyles = makeStyles(theme => getButtonClasses(theme));

const ActiveButton = props => {
  const classes = useButtonStyles();

  return (
    <Button {...props} classes={classes} variant="contained" color="primary" />
  );
};

export default ActiveButton;
