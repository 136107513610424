import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "infinite-react-carousel";

// Images
import office1 from "../../../../assets/images/Office1.png";
import office2 from "../../../../assets/images/Office2.png";
import office3 from "../../../../assets/images/Office3.png";

// Styles
import { values } from "../../../../styles/styles";
import colors from "../../../../styles/colors";

const useStyles = makeStyles(theme => ({
  root: {
    width: "45.16vw",

    "& > div": {
      height: "30.10vw",
      boxShadow: values.boxShadow2
    }
  },
  container: {
    overflow: "hidden",
    position: "relative",
    width: "45.16vw",
    height: "30.10vw"
  },
  image: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "100%",
    minHeight: "100%",
    width: "100%",
    height: "100%"
  },
  dots: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(3),
    listStyle: "none",

    "& > li": {
      "& > button": {
        color: "transparent",
        border: "none",
        backgroundColor: colors.dustyGray,
        margin: theme.spacing(0, 1),
        height: theme.spacing(2),
        width: theme.spacing(2),
        borderRadius: "100%",

        "&:focus": {
          outline: "none"
        }
      },

      "&.carousel-dots-active": {
        "& > button": {
          backgroundColor: colors.violetRed
        }
      }
    }
  }
}));

const CultureCarousel = () => {
  const classes = useStyles();

  return (
    <Slider
      className={classes.root}
      dotsClass={classes.dots}
      arrows={false}
      dots
    >
      <div className={classes.container}>
        <img src={office1} alt="Our office" className={classes.image} />
      </div>
      <div className={classes.container}>
        <img src={office2} alt="Coffee break" className={classes.image} />
      </div>
      <div className={classes.container}>
        <img src={office3} alt="Planning meeting" className={classes.image} />
      </div>
    </Slider>
  );
};

export default CultureCarousel;
