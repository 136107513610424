import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

// Assets
import menuIcon from "../../assets/icons/menu_icon.svg";

// Config
import { routes } from "../../routes/routesConfig";

// Components
import BuffLogo from "../../components/BuffLogo";
import Link from "../../components/Link";
import Text from "../../components/Text";
import DrawerContent from "./DrawerContent";

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles(theme => ({
  root: {
    height: ({ sticky }) => (sticky ? 70 : 0),
    padding: theme.spacing(0, 4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    transition: "all .2s ease-in-out",
    width: "100%"
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  logo: {
    marginRight: theme.spacing(2),
    width: 53
  },
  title: {
    margin: 0
  },
  button: {
    marginLeft: "auto",
    height: 24,
    width: 24
  },
  drawer: {
    width: "100vw",
    padding: theme.spacing(3, 0)
  }
}));

const NavListMobile = ({ sticky }) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const classes = useStyles({ sticky });

  const { title } = routes.find(({ path }) => path === pathname);

  const toggleMenu = value => () => setOpen(value);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Link className={classes.logo} to="/">
          <BuffLogo />
        </Link>
        {title && (
          <Text type="title" className={classes.title}>
            {title}
          </Text>
        )}
        <ButtonBase onClick={toggleMenu(true)} className={classes.button}>
          <img src={menuIcon} alt="menu" />
        </ButtonBase>
      </div>
      <Hidden mdUp>
        <SwipeableDrawer
          classes={{ paper: classes.drawer }}
          open={open}
          onClose={toggleMenu(false)}
          onOpen={toggleMenu(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          <DrawerContent onClose={toggleMenu(false)} />
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
};

NavListMobile.propTypes = {
  sticky: PropTypes.bool.isRequired
};

export default NavListMobile;
