import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { AboutSection, CareersSection, HeroSection } from "./sections";
import { rawClasses } from "../../styles/styles";

const useStyles = makeStyles(theme => ({
  root: {
    ...rawClasses.main,
    [theme.breakpoints.down("md")]: {
      ...rawClasses.mainMd
    },
    [theme.breakpoints.down("sm")]: {
      ...rawClasses.mainMobile
    }
  }
}));

const OverviewPage = () => {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <HeroSection />
      <AboutSection />
      <CareersSection />
    </main>
  );
};

export default OverviewPage;
