import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Icons
import arrowIcon from "../../assets/icons/arrow.svg";
import arrowIconMobile from "../../assets/icons/arrow_responsive.svg";

// Components
import Text from "../Text";

// Utils
import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 635,
    margin: theme.spacing(0, 8),

    [theme.breakpoints.down("md")]: {
      width: 405
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: theme.spacing(0, 5),
      margin: theme.spacing(0, 0, 2),
      maxWidth: 500
    }
  },
  heading: {
    color: colors.violetRed,
    margin: theme.spacing(0, 0, 1),
    fontSize: 20,
    textTransform: "capitalize",

    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  },
  arrowLeft: {
    height: 16,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      height: 12
    }
  },
  arrowRight: {
    height: 16,

    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      height: 12
    }
  },
  title: {
    textTransform: "capitalize"
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    textTransform: "capitalize"
  },
  description: {
    color: colors.tundora
  }
}));

const FeatureItem = ({
  align,
  className,
  description,
  heading,
  id,
  subtitle,
  title
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const arrowSrc = matches ? arrowIconMobile : arrowIcon;
  const arrow = {
    left: (matches || align === "left") && (
      <img alt="feature" src={arrowSrc} className={classes.arrowLeft} />
    ),
    right: align === "right" && (
      <img alt="feature" src={arrowSrc} className={classes.arrowRight} />
    )
  };

  return (
    <article id={id} className={clsx(classes.root, className)}>
      <Text type="small" className={classes.heading} align={align}>
        {arrow.left}
        {heading}
        {!matches && arrow.right}
      </Text>
      <Text type="title" className={classes.title} align={align}>
        {title}
      </Text>
      <Text type="subtitle" className={classes.subtitle} align={align}>
        {subtitle}
      </Text>
      {!matches && (
        <Text type="description" className={classes.description} align={align}>
          {description}
        </Text>
      )}
    </article>
  );
};

FeatureItem.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

FeatureItem.defaultProps = {
  align: null,
  className: null,
  id: null
};

export default FeatureItem;
