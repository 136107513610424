import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Components
import Link from "../../components/Link";
import Text from "../../components/Text";

// Config
import { routes } from "../../routes/routesConfig";
import colors from "../../styles/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(7),
    marginBottom: theme.spacing(9)
  },
  link: {
    display: "flex",
    textDecoration: "none"
  },
  text: {
    borderBottom: "3px solid white"
  },
  selected: {
    borderColor: colors.violetRed
  }
}));

const RouteItem = ({ onClick }) => {
  const classes = useStyles();
  return (
    <nav className={classes.root}>
      {routes.map(
        route =>
          route.id !== "404" && (
            <Route key={route.id} path={route.path}>
              {({ match }) => (
                <Link
                  onClick={onClick}
                  to={route.path}
                  className={classes.link}
                >
                  <Text
                    type="title"
                    className={clsx(classes.text, {
                      [classes.selected]: Boolean(match)
                    })}
                  >
                    {route.title}
                  </Text>
                </Link>
              )}
            </Route>
          )
      )}
    </nav>
  );
};

RouteItem.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default RouteItem;
