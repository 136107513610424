import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

// Components
import Form from "./Form";
import Sponsors from "../../../../components/Sponsors";
import Text from "../../../../components/Text";

// Assets
import colors from "../../../../styles/colors";
import imgComp from "../../../../assets/images/img_comp.png";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(20, 0),
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.whiteLilac,

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(10, 14)
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(7, 5.5)
    }
  },
  contentWrapper: {
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    padding: theme.spacing(0, 0, 5),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      margin: 0,
      padding: 0
    }
  },
  info: {
    width: "21vw",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginRight: "7.5vw",

    [theme.breakpoints.down("md")]: {
      width: "26.6vw"
    },

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      width: 240,
      margin: "0 auto"
    }
  },
  heading: {
    textTransform: "capitalize",

    [theme.breakpoints.up("md")]: {
      fontSize: 36
    }
  },
  text: {
    marginBottom: theme.spacing(5),
    fontWeight: theme.typography.fontWeightRegular,
    maxWidth: 400,

    [theme.breakpoints.up("md")]: {
      fontSize: 22,
      lineHeight: 1.3
    }
  },
  image: {
    width: "100%",
    position: "absolute",
    left: 0,
    bottom: 0,

    [theme.breakpoints.down("md")]: {
      bottom: "10%",
      position: "unset"
    },

    [theme.breakpoints.down("sm")]: {
      position: "relative",
      bottom: 0,
      marginRight: -35
    }
  },
  sponsorsWrapper: {
    margin: "0 auto"
  },
  alreadyWorking: {
    color: colors.frenchGray,
    fontSize: 26,
    marginBottom: theme.spacing(7.5)
  },
  sponsors: {
    margin: "0 auto",
    padding: "0 3vw"
  }
}));

const LetsTalkSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id="stay">
      <div className={classes.contentWrapper}>
        <div className={classes.info}>
          <Text className={classes.heading} type="title">
            Lets talk
          </Text>
          <Text className={classes.text} type="description">
            Drop us a line, send us your resume or ask for a demo
          </Text>
          <img src={imgComp} className={classes.image} alt="Get in touch" />
        </div>
        <Form />
      </div>
      <Hidden smDown>
        <div className={classes.sponsorsWrapper}>
          <Text type="body2" className={classes.alreadyWorking}>
            We are already working on them
          </Text>
          <Sponsors classes={{ root: classes.sponsors }} />
        </div>
      </Hidden>
    </section>
  );
};

export default LetsTalkSection;
