import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/icons/marker.svg";

const key = "AIzaSyCWX1NCiGjcxAmWhovxmjix0jlB1j3qeMY";
const mapProps = {
  center: {
    lat: 51.503692,
    lng: -0.11593
  },
  zoom: 16,
  bootstrapURLKeys: { key }
};
const useStyles = makeStyles(() => ({
  marker: {
    position: "relative"
  },
  img: {
    position: "absolute",
    top: "-5.5vw",
    left: "-4.55vw",
    width: "5.95vw"
  }
}));
const GoogleMap = () => {
  const classes = useStyles();

  const Marker = () => (
    <div className={classes.marker}>
      <img src={marker} alt="Marker" className={classes.img} />
    </div>
  );

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact {...mapProps}>
        <Marker {...mapProps.center} />
      </GoogleMapReact>
    </div>
  );
};

export default GoogleMap;
